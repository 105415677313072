<template>
  <div slot="header" class="clearfix card-header">
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-button
                type="primary"
                @click="added()"
                size="medium"
            >新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-table
        :data="departList"
        row-key="id"
        size="small"
        height="700px"
        style="width: 50%"
        border
        stripe
        default-expand-all
        :row-style="{height:'10px'}"
        :cell-style="{padding:'1px 0'}"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="label" label="部门名称" sortable width="250" fixed="left" align="center"></el-table-column>
      <el-table-column prop="created" label="创建时间" width="210" align="center"></el-table-column>
      <el-table-column prop="remark" label="备注" width="180" align="center"></el-table-column>
            <el-table-column prop="icon" label="操作" width="180" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button @click="editHandler(scope.row.id)" type="text" size="small">编辑</el-button>
                <el-divider direction="vertical"></el-divider>
                <template>
                  <el-popconfirm
                      confirm-button-text="确认"
                      cancel-button-text="取消"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确认删除菜单信息？"
                      @confirm="delHandle(scope.row.id)"
                  >
                    <el-button type="text" size="small" slot="reference">删除</el-button>
                  </el-popconfirm>
                </template>
              </template>
            </el-table-column>
    </el-table>
    <el-dialog title="新增部门" :visible.sync="dialogVisible" width="600px" :before-close="handleClose" :close-on-click-modal="false">
      <el-form
          :model="editForm"
          :rules="editFormRules"
          ref="editForm"
          label-width="100px"
          class="demo-editForm"
      >
        <el-form-item label="上级部门">
          <el-select class="main-select-tree" ref="selectTree" v-model="value" placeholder="请选择上级部门">
            <el-option v-if="editForm.id === undefined" label="一级部门" :value="0"></el-option>
            <el-option v-for="item in formatData(departList)" :key="item.value" :label="item.label" :value="item.value" style="display: none;" />
            <el-tree class="main-select-el-tree" ref="selecteltree"
                     :data="depart"
                     node-key="id"
                     highlight-current
                     :props="defaultProps"
                     @node-click="handleNodeClick"
                     :current-node-key="value"
                     :expand-on-click-node="expandOnClickNode"
                     default-expand-all />
          </el-select>
        </el-form-item>
        <el-form-item label="部门名称" prop="label">
          <el-input v-model="editForm.label" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="editForm.remark" type="textarea" maxlength="200" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="noSubmit('editForm')">保存</el-button>
        <el-button @click="resetForm('editForm')">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/user";
export default {
  name:"Departs",
  data(){
    return{
      value: 0,
      expandOnClickNode: true,
      options:[],
      dialogVisible:false,
      editForm:{},
      editFormRules: {
        label: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
      },
      departList:[],
      depart:[],
      deId:"",
      depInput:"",
      defaultProps: {
        children: 'children',
        label: 'label'
      },
    }
  },
  created(){
    this.getDepaert();
  },
  methods:{
    getDepaert(){
      api.getQueryDepart(this.depInput).then(res => {
        this.departList = res.data.data.result;
        this.depart = res.data.data.result;
      })
    },
    added(){
      this.dialogVisible = true;
      this.label = "";
      this.value = 0;
    },
    noSubmit(form){
      this.$refs[form].validate(valid => {
        if(valid){
          let updateOrSave = this.editForm.id ? "update":"save";
          if(this.value === this.deId){
            this.$notify({
              title: "prefect",
              message: "不能选择当前部门",
              type: "error",
              position: "bottom-right",
            });
            return;
          }
          api.saveOrUpdateDepart(updateOrSave,this.editForm,this.value).then(res => {
            if(res.data.code === 200){
              this.$notify({
                title: "prefect",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
            }else if(res.data.code === 400){
              this.$notify({
                title: "prefect",
                message: "操作失败,该部门已存在",
                type: "error",
                position: "bottom-right",
              });
            }else {
              this.$notify({
                title: "prefect",
                message: "操作失败",
                type: "error",
                position: "bottom-right",
              });
            }
            this.dialogVisible = false;
            this.editForm = {};
            this.getDepaert();
          })
        }else {
          return false;
        }
      })
    },
    formatData(data){
      let options = [];
      data.forEach((item,key) => {
        options.push({label:item.label,value:item.id});
        if(item.children){
          item.children.forEach((items,keys) => {
            options.push({label:items.label,value:items.id});
            if(items.children){
              items.children.forEach((itemss,keyss) => {
                options.push({label:itemss.label,value:itemss.id});
                if(itemss.children){
                  itemss.children.forEach((itemsss,keyss) =>{
                    options.push({label:itemsss.label,value:itemsss.id});
                    if(itemsss.children){
                      itemsss.children.forEach((itemssss,keyss) =>{
                        options.push({label:itemssss.label,value:itemssss.id});
                        if(itemssss.children){
                          itemssss.children.forEach((itemsssss,keyss) =>{
                            options.push({label:itemsssss.label,value:itemsssss.id});
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
      return options;
    },
    editHandler(id){
      api.queryById(id).then(res => {
        this.editForm = res.data.data.result;
        this.deId = res.data.data.result.id;
        this.value = res.data.data.result.parentId;
        this.dialogVisible = true;
        if(this.editForm.parentId === 0){
          let options = [];
          options.push({label:this.editForm.label,id:this.editForm.id,parentId:this.editForm.parentId,remark:this.editForm.remark});
          this.depart = options;
        }else{
          api.getQueryDepart(this.depInput).then(res => {
            this.depart = res.data.data.result;
          })
        }
      })
    },
    delHandle(id){
      api.getDeleteById(id).then(res =>{
        if(res.data.data.result === "200"){
          this.$notify({
            title: "prefect",
            message: "操作成功",
            type: "success",
            position: "bottom-right",
          });
        }else if(res.data.data.error === "400"){
          this.$notify({
            title: "prefect",
            message: "操作失败,该部门下有子部门。",
            type: "error",
            position: "bottom-right",
          });
        }else if(res.data.data.error === "406"){
          this.$notify({
            title: "prefect",
            message: "操作失败,该部门下存在人员。",
            type: "error",
            position: "bottom-right",
          });
        }else{
          this.$notify({
            title: "prefect",
            message: "操作失败",
            type: "error",
            position: "bottom-right",
          });
        }
        this.getDepaert();
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },
    handleNodeClick(node){
      this.value = node.id;
      this.$refs.selectTree.blur();
    }
  }
}
</script>

<style>
.main-select-el-tree .el-tree-node .is-current > .el-tree-node__content{font-weight: bold; color: #409eff;}
.main-select-el-tree .el-tree-node.is-current > .el-tree-node__content{font-weight: bold; color: #409eff;}
</style>
